import React from "react";
import { graphql } from "gatsby";
import { PrevNextPage, VideoPlayer, Seo, PageBanner } from "../components";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import tw, { styled } from "twin.macro";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { ImQuotesLeft } from "react-icons/im";

const BoldWrapper = styled.span`
  ${tw`text-secondary !font-bold`}
`;
const UnderlineWrapper = styled.span`
  ${tw`underline`}
  text-decoration-color: var(--text-secondary);
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
`;

const ImageWrapper = tw.div`w-full flex content-center`;

const Paragraph = styled.p`
  ${tw`tracking-normal list-decimal list-inside leading-5 whitespace-pre-wrap my-2`}
  text-indent: 2rem;
`;

const ContentDiv = styled.article`
  ${tw`rounded-2xl px-4 md:px-[32px] lg:px-[110px] py-6 md:py-[64px] lg:py-[80px] mx-4 md:mx-16 my-9 shadow-two bg-secondary text-primary relative`}

  .quote-icon {
    ${tw`text-[5rem] text-rblue absolute left-4 top-2 lg:(left-8 top-6) opacity-30`}
  }

  blockquote {
    ${tw`text-center text-xl font-bold`}
  }

  blockquote > span {
    ${tw`block my-2 text-sm font-normal`}
  }

  div.main * {
    ${tw`text-base font-normal antialiased`}
  }

  footer {
    ${tw`w-full text-right mt-8`}
  }

  footer * {
    ${tw` font-normal text-primary`}
  }
`;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <BoldWrapper>{text}</BoldWrapper>;
    },
    [MARKS.UNDERLINE]: (text) => {
      return <UnderlineWrapper>{text}</UnderlineWrapper>;
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <Paragraph>{children}</Paragraph>;
    },
    "embedded-asset-block": (node) => {
      const { gatsbyImageData } = node.data.target;
      if (!gatsbyImageData) {
        return null;
      }
      return (
        <ImageWrapper>
          <GatsbyImage image={gatsbyImageData} loading="lazy" />
        </ImageWrapper>
      );
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 1 && <br key={index} />, textSegment];
    }, []);
  },
};

const MessageTemplate = ({ data, pageContext: { prevPage, nextPage } }) => {
  const {
    title,
    passage,
    keyVerseQuote,
    keyVerseRef,
    deliveredOn,
    videoLink,
    content,
    author,
  } = data.contentfulMessage;
  const authorNames = author
    .reduce((acc, name) => `${acc} ${name}, `, "")
    .slice(0, -2);
  const quote = keyVerseQuote ? keyVerseQuote.keyVerseQuote : "Key Verse";

  return (
    <>
      <Seo
        title={title}
        description={`Ryerson University Bible Fellowship Sunday Message ${title} published at ${deliveredOn}`}
        author={authorNames}
      />
      <PageBanner
        img={
          <StaticImage
            src={"../images/aaron-burden-pCfJKUB5bes-unsplash.jpg"}
            alt="blurred gray stone wall background"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title={title}
        createdAt={deliveredOn}
      />
      <div className="page-main">
        <ContentDiv>
          <ImQuotesLeft className="quote-icon" />
          <blockquote>
            {`${quote ? quote : ""} ${
              keyVerseRef ? "(" + keyVerseRef + ")" : ""
            }`}
            {passage ? <span> Passage: {passage ? passage : ""}</span> : ""}
          </blockquote>
          {videoLink ? (
            <VideoPlayer videoId={videoLink.split("https://youtu.be/")[1]} />
          ) : null}
          <div className="main">
            {content && renderRichText(content, options)}
          </div>
          <footer>
            {authorNames && <address rel="author">{authorNames}</address>}
            <p>Young Disciple Christian Fellowship &#8211; RU</p>
            {deliveredOn && (
              <time pubdate="pubdate" dateTime={deliveredOn}>
                {deliveredOn}
              </time>
            )}
          </footer>
        </ContentDiv>
        <PrevNextPage {...{ target: "message", prevPage, nextPage }} />
      </div>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulMessage(slug: { eq: $slug }) {
      slug
      title
      passage
      keyVerseQuote {
        keyVerseQuote
      }
      keyVerseRef
      author
      deliveredOn(formatString: "MMMM Do, YYYY")
      videoLink
      content {
        raw
      }
    }
  }
`;

export default MessageTemplate;
